<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dati Identificativi" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3" style="margin: 0">
                    <base-input
                      name="title"
                      vid="title"
                      label="Titolo"
                      v-model="form[rep].title"
                      placeholder="Inserisci un titolo"
                      @keyup="onInputTitle"
                      :rules="{ required: true }"
                    />
                    <div
                      class="custom-invalid-feedback"
                      style="
                        width: 100%;
                        margin-top: 0.25rem;
                        font-size: 0.875em;
                        color: #dc3545;
                      "
                      v-show="form[rep].title && !validName"
                    >
                      Esiste già un'immagine salvata con lo stesso titolo. Per
                      favore inserire un titolo differente per poter procedere
                      al salvataggio.
                    </div>
                  </div>
                </b-row>
                <b-table
                  small
                  :fields="fields"
                  :items="items"
                  responsive="sm"
                  style="margin-top: 1rem"
                >
                  <template #cell(filesystem)="data">
                    <b-form-file
                      v-model="data.item.file"
                      :state="Boolean(data.item.file)"
                      placeholder="Scegli un file o trascinalo qui..."
                      drop-placeholder="Trascina il file qui..."
                      browse-text="Naviga"
                    ></b-form-file>
                  </template>
                  <template #cell(delete)="data">
                    <b-button
                      size="sm"
                      variant="lisaweb"
                      @click="data.item.file = null"
                      :disabled="data.item.file ? false : true"
                    >
                      Reset
                    </b-button>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid || (form[rep].title && !validName)"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button @click="resetForm()" variant="lisaweb" size="sm"
                >Reset</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";

export default {
  mixins: [ShortcutMixin, LisaComunicaMixin],
  data() {
    return {
      repository: "image",
      isLoading: false,
      validName: false,
      id: this.$route.params.id,
      form: {
        image: {
          title: null,
          image: null,
        },
      },
      fields: [
        {
          key: "filesystem",
          thStyle: {
            display: "none",
          },
          tdClass: "col-md-2",
        },
        {
          key: "delete",
          thStyle: {
            display: "none",
          },
          tdClass: "col-md-3",
        },
      ],
      items: [{ file: null }],
      image_list: [],
      image_list_titles: [],
    };
  },
  methods: {
    getImagesTitles() {
      this.image_list_titles = this.image_list.map((image) => image.title);
    },
    onInputTitle() {
      this.validName = this.form[this.rep].title
        ? !this.image_list_titles.includes(this.form[this.rep].title.trim())
        : false;
    },
    onSubmit() {
      this.isLoading = true;
      this.form[this.rep].image = this.items[0].file;
      let formData = new FormData();
      formData.append("title", this.form[this.rep].title);
      formData.append("image", this.form[this.rep].image);
      this.store(formData)
        .then(() => {
          this.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Immagine Caricata`,
          });
          this.shortcut("lisacomunica_template", null, "#Image", "filterImage");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
  },
  components: {
    BaseIcon,
    BaseInput,
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  mounted() {
    this.index("", "image")
      .then((response) => {
        this.image_list = response?.data?.content;
        if (this.image_list.length) this.getImagesTitles();
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      });
  },
};
</script>

<style lang="scss" scoped>
:deep(tbody) {
  border-top: 0px !important;
}
:deep(table td) {
  border-top: 0px solid #dee2e6;
}
</style>
