<template>
  <div class="mt-1 mx-3">
    <images-create></images-create>
  </div>
</template>

<script>
import ImagesCreate from "@/components/profile/lisacomunica/images/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    ImagesCreate,
  },
  created() {
    this.notifyQueue({
      text: "Immagini",
      path: "lisacomunica_template",
      tab: "Images",
      level: 3,
    });
    this.notifyQueue({
      text: "Inserisci template immagine",
      path: "images.create",
      level: 4,
    });
  },
};
</script>
